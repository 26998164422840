<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <v-card v-if="!dataLoaded">
          <v-card-title>Program Change Form (Loading...)</v-card-title>
          <v-card-text>Loading your program change form. This should only take a few seconds.</v-card-text>
        </v-card>
        <template v-else>
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Program Change Status: {{ termLabel }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h3>Current Status: {{ status }}</h3>
              <p v-if="status === 'Submitted'">You have submitted your change request. The Records office will do an initial review of this request, and will adjust the advisors as needed. Once that is finished, the request will be sent to your advisors for approval.</p>
              <p v-else-if="status === 'Pending'"></p>
            </v-card-text>
          </v-card>
          <submission-card :data="submission" class="mt-2 mb-2"></submission-card>
          <v-card v-if="showAddMessage">
            <v-card-text>
              <h3>Add a Message</h3>
              <v-textarea v-model="comment" label="Message" style="margin-top:1em" rows="3" outlined hide-details></v-textarea>
              <v-checkbox v-model="emailAdvisors" label="Email this message to my advisor(s)"></v-checkbox>
              <v-checkbox v-model="emailRecords" label="Email this message to the Records Office"></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="comment === ''" @click="addComment" color="info">Add Message</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="showAddMessage = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          <v-btn v-else @click="showAddMessage = true">Add Message</v-btn>
          <h3 style="margin-top:1em">Change Request Timeline</h3>
          <v-timeline dense>
            <v-timeline-item v-for="({ name, date, text, icon, color }, index) in timeline" :key="'timeline-' + index" :color="color">
              <template v-slot:icon>
                <v-icon small color="white">{{ icon }}</v-icon>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>{{ name }}</v-col>
                    <v-col class="text-right">{{ stringFormatDate(date) }}</v-col>
                  </v-row>
                  <v-divider style="margin: .2em 0"></v-divider>
                  <p style="margin-bottom:0" v-html="text"></p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { onMounted, ref, computed, reactive } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    SubmissionCard: () => import('@/components/student/program-change/submission')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const service = root.$feathers.service('student/major-change')
    const dataLoaded = ref(false)
    const term = ref('')
    const termLabel = ref('')
    const status = ref('')
    const submission = reactive({})
    const timeline = ref([])

    const majorItems = ref([])
    const minorItems = ref([])

    onMounted(async () => {
      // Load the PIDM of the current user
      let pidm = user.value.pidm
      // Get possible terms from the term warehouse
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { start: { $lt: new Date() }, end: { $gt: new Date() } } })
      let terms = []
      for (let i = 0; i < termData.length; i++) {
        terms.push(termData[i].term)
      }
      // Load the student's academic data from the student/term-detail
      let { data: studentData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, term: { $in: terms } } })
      if (studentData.length > 0) {
        let { term: termCode } = studentData[0]
        term.value = termCode
        termData.forEach(({ term, label }) => { if (term === termCode) termLabel.value = label })
        dataLoaded.value = true
      } else {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'No enrollment details were found for you in any active term. Please contact the Records office for assistance.' })
        root.$router.replace('/')
      }
      // Check to see if the student has a pending submission; if not, redirect them to the form
      let { data: changeData } = await service.find({ query: { pidm, term: term.value } })
      if (changeData.length === 0) {
        root.$router.replace('/student/program-change/')
      } else {
        status.value = changeData[0].status
        for (const field in changeData[0]) {
          submission[field] = changeData[0][field]
        }
        timeline.value = changeData[0].timeline.filter(({ visibleToStudent }) => !!visibleToStudent)
        // findDiffs(original.value.major.filter((val) => val !== ''), majr.filter((val) => val !== ''), 'Major')
        // findDiffs(original.value.minor.filter((val) => val !== ''), minr.filter((val) => val !== ''), 'Minor')
        // findDiffs(original.value.concentration.filter((val) => val !== ''), conc.filter((val) => val !== ''), 'Concentration')
        // findDiffs(original.value.certificate.filter((val) => val !== ''), cert.filter((val) => val !== ''), 'Certificate')
        // findDiffs(original.value.advisor.filter((val) => val !== '').map(({ pidm }) => pidm), advr.filter((val) => val !== ''), 'Advisor')
      }
      dataLoaded.value = true
    })

    const showAddMessage = ref(false)
    const comment = ref('')
    const emailAdvisors = ref(false)
    const emailRecords = ref(false)
    function addComment () {}

    return {
      user,
      dataLoaded,
      term,
      termLabel,
      status,
      submission,
      timeline,
      majorItems,
      minorItems,
      showAddMessage,
      comment,
      emailAdvisors,
      emailRecords,
      addComment,
      stringFormatDate
    }
  }
}
</script>
